import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GooglePayButton from '@google-pay/button-react';
import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { selectCustomer } from '../../features/customer/customerSlice';
import CustomerObj from '../../features/customer/customerObj';
import { selectCartItems, selectOrderInProgress } from '../../features/cart/cartSlice';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { selectProducts } from '../../features/products/productsSlice';
import CartObj from '../../features/cart/cartObj';
import { FormValues } from '../checkout-form/interfaces';
import { selectToken } from '../../features/user/userSlice';
import AuthNetAPI from '../../API/authNetAPI';
import { selectGeneralOptions } from '../../features/mobile/mobileSlice';
import { useStripe } from '@stripe/react-stripe-js';
import Helper from '../../utils/Helper';
import { selectGuestEmail } from '../../features/guest/guestSlice';
import validateForm from './validateForm';
import DeliveryObj from '../../features/delivery/deliveryObj';
import { selectDeliveryData } from '../../features/delivery/deliverySlice';

interface Props {
    values: FormValues;
    totalValue: any;
    setErrorMsg: (errorMsg: string) => void;
    handleOnSubmit: (values: FormValues, isSavedPayment: boolean, onlinePayment: boolean, PaymentMethodTitle: string, transactionId?: any) => void;
}

export default function PaymentOptions({ values, totalValue, setErrorMsg, handleOnSubmit }: Props) {
    const stripe = useStripe();
    // const elements = useElements();
    const elements = stripe && stripe.elements();
    let token = useSelector(selectToken);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [canMakePayments, setCanMakePayments] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const guestEmail = useSelector(selectGuestEmail);
    const custData = useSelector(selectCustomer);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const cartItems = useSelector(selectCartItems);
    const cart = useMemo(() => {
        return new CartObj(cartItems);
    }, [cartItems]);
    const [isFormValid, setIsFormValid] = useState(false);
    const products = useSelector(selectProducts);
    const orderInProgress = useSelector(selectOrderInProgress);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const shippingMethods = useSelector(selectShippingMethods);
    const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
    const publishKey = getGeneralOptions?.payment_mode === 'live' ?
        process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY :
        process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY;
    const totalPay = parseFloat(totalValue.replace('$', ''));
    const paymentOption = getGeneralOptions?.payment_option;
    const getPaymode = getGeneralOptions?.payment_mode && getGeneralOptions?.payment_mode;
    const paymentMode = getPaymode === 'test' ? 'TEST' : 'PRODUCTION';
    const paymentGateway = paymentOption === 'stripe' ? paymentOption : 'authorizenet';
    const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
    const authMerchantId = getPaymode === 'test' ?
        process.env.REACT_APP_STAGE_AUTHORIZE_MERCHANTID :
        process.env.REACT_APP_PROD_AUTHORIZE_MERCHANTID;
    const stripeMerchantId = getPaymode === 'test' ?
        process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY :
        process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY;
    const gatewayMerchantId = paymentOption === 'stripe' ? stripeMerchantId : authMerchantId || '';
    const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};

    useEffect(() => {
        // Check if Apple Pay is available on the current device
        if ((window as any).ApplePaySession) {
            setCanMakePayments(true);
        }
    }, []);

    useEffect(() => {
        const validationStatus = validate(values);
        const isFormValid = Object.keys(validationStatus).length === 0;

        setIsFormValid(isFormValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const validate = (values: FormValues) => {
        const shippingMethodID = values?.shipping_method;
        const shippingMethodInputs = document.getElementsByName('shipping-method');

        const isUPSOrFreeShipping = (title: string) =>
            title === 'UPS Shipping' || title === 'UPS Free Shipping' || title === 'Free Shipping';

        if (shippingMethodInputs.length > 0) {
            const firstShippingMethodValue = (shippingMethodInputs[0] as HTMLInputElement)?.value;

            if (firstShippingMethodValue && shippingMethodID !== Number(firstShippingMethodValue)) {
                const shippingMethodByDate = ShippingMethodObj.getById(shippingMethods, Number(firstShippingMethodValue));
                return validateForm(creditCardProcessor, values, token, deliveryInfo, cart, products, isUPSOrFreeShipping(shippingMethodByDate?.data.title), totalValue, false, true);
            }
        }

        const shippingMethodByDate = ShippingMethodObj.getById(shippingMethods, Number(shippingMethodID));
        return validateForm(creditCardProcessor, values, token, deliveryInfo, cart, products, isUPSOrFreeShipping(shippingMethodByDate?.data.title), totalValue, false, true);
    }

    const initialEmailValue = () => {
        if (token && billing_info && billing_info.email) {
            return 'email' in billing_info ? billing_info.email : ''
        } else if (token && customer && customer?.data?.email) {
            return customer?.data ? customer?.data?.email : ''
        }

        return guestEmail;
    }

    const handleApplePayPayment = async () => {
        if (!isFormValid) {
            setErrorMessage("Ensure all fields are completed correctly.");
            return;
        }

        if (!(window as any).ApplePaySession) {
            alert('Apple Pay is not available on this device.');
            return;
        }

        if (creditCardProcessor === 'authorize.net') {
            const paymentRequest = {
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    label: 'MightyMeals (SANDBOX)',
                    amount: totalPay.toString(),
                },
                supportedNetworks: ['visa', 'masterCard', 'amex'],
                merchantCapabilities: ['supports3DS'],
                // requiredBillingContactFields: ['postalAddress', 'name'],
                // requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],
                version: 3,
                merchantIdentifier: 'merchant.com.emmstaging.autoship',
            };

            const session = new (window as any).ApplePaySession(3, paymentRequest);
            const validationURLStaging = "https://apple-pay-gateway-cert.apple.com/paymentservices/startSession";

            session.onvalidatemerchant = async (event: { validationURL: string }) => {
                try {
                    const merchantSession = await AuthNetAPI.applepayvalidatemerchant(validationURLStaging);
                    session.completeMerchantValidation(merchantSession);
                } catch (error: any) {
                    setErrorMsg('Something went wrong. Please try again later.');
                    console.log('Merchant validation failed: ' + error.message);
                }
            };

            session.onpaymentauthorized = async (event: {
                payment: {
                    shippingContact: any;
                    billingContact: any;
                    token: { paymentData: any; };
                };
            }) => {
                const paymentData = event.payment.token.paymentData;
                const billingContact = event.payment.billingContact;
                const shippingContact = event.payment.shippingContact;

                const address_appleContact = shippingContact ? shippingContact : billingContact;
                const address_info = shipping_info ? shipping_info : billing_info;

                try {
                    const billingContactObj = {
                        firstName: billing_info && 'first_name' in billing_info ? billing_info.first_name : billingContact.givenName,
                        lastName: billing_info && 'last_name' in billing_info ? billing_info.last_name : billingContact.familyName,
                        email: initialEmailValue(),
                        phone: billing_info && 'phone' in billing_info ? Helper.formatPhoneNo(billing_info.phone) : '',
                        address: billing_info && 'address_1' in billing_info ? billing_info.address_1 : billingContact.addressLines.join(', '),
                        city: billing_info && 'city' in billing_info ? billing_info.city : billingContact.locality,
                        state: billing_info && 'state' in billing_info ? billing_info.state : billingContact.administrativeArea,
                        zipCode: billing_info && 'postcode' in billing_info ? billing_info.postcode : billingContact.postalCode,
                        country: 'US'
                    };

                    const shippingContactObj = {
                        firstName: address_info && 'first_name' in address_info ? address_info.first_name : address_appleContact.givenName,
                        lastName: address_info && 'last_name' in address_info ? address_info.last_name : address_appleContact.familyName,
                        address: address_info && 'address_1' in address_info ? address_info.address_1 : address_appleContact.addressLines.join(', '),
                        city: address_info && 'city' in address_info ? address_info.city : address_appleContact.locality,
                        state: address_info && 'state' in address_info ? address_info.state : address_appleContact.administrativeArea,
                        zipCode: address_info && 'postcode' in address_info ? address_info.postcode : address_appleContact.postalCode,
                        country: 'US'
                    };

                    const paymentRequest = {
                        dataDescriptor: "COMMON.APPLE.INAPP.PAYMENT",
                        dataValue: btoa(JSON.stringify(paymentData))
                    };

                    AuthNetAPI.applepayProcessPayment(
                        paymentRequest,
                        billingContactObj,
                        shippingContactObj,
                        totalPay
                    ).then((response: any) => {
                        if (response.status === "success") {
                            session.completePayment(ApplePaySession.STATUS_SUCCESS);
                            handleOnSubmit(values, false, true, 'Apple Pay', response.transactionId);
                        } else {
                            session.completePayment(ApplePaySession.STATUS_FAILURE);
                            setErrorMsg('Payment processing failed please try again later.');
                            console.log('Payment processing failed: ' + response.message);
                        }
                    });
                } catch (error) {
                    console.error("Payment processing error:", error);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                }
            };

            session.begin();
        } else if (creditCardProcessor === 'stripe') {
            if (!stripe || !elements) {
                console.error("Stripe or Elements not loaded yet.");
                alert("Stripe or Elements not loaded yet.");
                return;
            }

            const paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'MightyMeals (SANDBOX)',
                    amount: 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestPayerPhone: true,
            });

            const canMakePayment = await paymentRequest.canMakePayment();

            if (canMakePayment && canMakePayment.applePay) {
                paymentRequest.on('paymentmethod', async (event) => {
                    try {
                        const paymentMethodId = event.paymentMethod.id;

                        AuthNetAPI.applepayStripePayment(
                            paymentMethodId,
                            100
                        ).then(async (response: any) => {
                            if (response.status === 'requires_action') {
                                const { error, paymentIntent } = await stripe.confirmCardPayment(response.clientSecret);

                                if (error) {
                                    alert("error: " + JSON.stringify(error));
                                    console.error("Payment confirmation failed:", error.message);
                                    setErrorMsg('Payment confirmation failed. Please try again.');
                                    event.complete('fail');
                                    return;
                                }

                                if (paymentIntent.status === 'succeeded') {
                                    event.complete('success');
                                    handleOnSubmit(values, false, true, 'Apple Pay');
                                } else {
                                    event.complete('fail');
                                }
                            } else if (response.status === 'success') {
                                event.complete('success');
                                handleOnSubmit(values, false, true, 'Apple Pay');
                            } else {
                                event.complete('fail');
                                setErrorMsg('Payment failed. Please try again later.');
                            }
                        });
                    } catch (error) {
                        alert("error message: " + JSON.stringify(error));
                        console.error("Payment process failed:", error);
                        setErrorMsg('Payment failed. Please try again later.');
                        event.complete('fail');
                    }
                });

                paymentRequest.show();
            } else {
                alert("Apple Pay is not available.");
            }
        }
    };

    const handlePaymentAuthorized = async (paymentData: any) => {
        console.log('Payment data:', paymentData);
        handleOnSubmit(values, false, true, 'Google Pay');
        return { transactionState: 'SUCCESS' } as google.payments.api.PaymentAuthorizationResult;
    };

    const handleLoadPaymentData = (paymentRequest: any) => {
        console.log('Load payment data:', paymentRequest);
    };

    const handleError = (error: any) => {
        console.log(error, "error")
        setErrorMessage('Uh oh, something went wrong. Please try again later.');
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    const handleGooglePayClick = async () => {
        if (!isFormValid) {
            setErrorMessage("Ensure all fields are completed correctly.");
            return;
        }
    };

    return (
        <>
            {errorMessage && (
                <Alert variant="danger" className='my-3 text-center error-message'>{errorMessage}</Alert>
            )}
            <div className='d-flex justify-content-center pb-2 fw-bold'>- OR -</div>
            <Row className='text-center paymentBtn'>
                <Col className="d-flex justify-content-center">
                    {canMakePayments && <>
                        <Button
                            className='px-3'
                            variant="dark"
                            disabled={orderInProgress}
                            onClick={handleApplePayPayment}>
                            <FontAwesomeIcon icon={faApple} />&nbsp;Pay
                        </Button> &nbsp;&nbsp;&nbsp;
                    </>}
                    {(!isFormValid || orderInProgress) ?
                        <Button className='px-3' disabled={orderInProgress} variant="dark" onClick={handleGooglePayClick}>
                            <FontAwesomeIcon icon={faGoogle} />&nbsp;Pay
                        </Button> :
                            <GooglePayButton
                                environment={paymentMode}
                                paymentRequest={{
                                    apiVersion: 2,
                                    apiVersionMinor: 0,
                                    allowedPaymentMethods: [
                                        {
                                            type: 'CARD',
                                            parameters: {
                                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                                            },
                                            tokenizationSpecification: {
                                                type: 'PAYMENT_GATEWAY',
                                                parameters: {
                                                    gateway: paymentGateway,
                                                    gatewayMerchantId: gatewayMerchantId || '',
                                                    'stripe:publishableKey': `${publishKey}`,
                                                    'stripe:version': '2020-08-27',
                                                },
                                            },
                                        },
                                    ],
                                    merchantInfo: {
                                        merchantId: 'BCR2DN4TRKDI3EIV',
                                        merchantName: 'MightyMeals',
                                    },
                                    transactionInfo: {
                                        totalPriceStatus: 'FINAL',
                                        totalPriceLabel: 'Total',
                                        totalPrice: `${totalPay}`,
                                        currencyCode: 'USD',
                                        countryCode: 'US',
                                    },
                                    shippingAddressRequired: false,
                                    callbackIntents: ['PAYMENT_AUTHORIZATION'],
                                    shippingAddressParameters: {
                                        allowedCountryCodes: ['US'],
                                    },
                                }}
                                onLoadPaymentData={handleLoadPaymentData}
                                onPaymentAuthorized={handlePaymentAuthorized}
                                onError={handleError}
                                existingPaymentMethodRequired={false}
                                buttonType="short"
                            />}
                </Col>
            </Row>
        </>
    )
}